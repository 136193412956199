import { useQuery } from '@tanstack/react-query';
import { IDynamicQueryCollection } from '../@types/DynamicInsightQueryCollection';
import { fetchQuestionCollections } from '../api/dynamicInsights.api';

export function useDynamicInsightsCollection(params) {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchQuestionCollections(params),
        queryKey: ['dynamic-insights-collection', params],
        refetchOnWindowFocus: false,
    });

    return {
        collections: data?.items || ([] as IDynamicQueryCollection[]),
        totalPages: data?.pages,
        ...rest,
    };
}
