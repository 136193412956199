import Flex from 'styled-flex-component';
import { Chip, IconButton, Tooltip } from '@mui/material';
import {
    CopyAll,
    Delete,
    Download,
    Label,
    LabelOff,
    QuestionAnswerOutlined,
} from '@mui/icons-material';
import { Theme } from '../../../theme';
import { IDocument } from '../../../@types/Document';
import { canChangeADocument } from '../../../utils/preventDocumentChanges';
import { toast } from 'react-toastify';

interface MultipleDocActionsProps {
    selectedDocuments: IDocument[];
    setIsAddTagOpen: (isOpen: boolean) => void;
    setIsDynamicInsightsOpen: (isOpen: boolean) => void;
    setTaggingMode: (mode: string) => void;
    documentTags: object;
    setShowDeleteDialog: (showDelete: boolean) => void;
}

const MultipleDocActions = ({
    selectedDocuments,
    setIsAddTagOpen,
    setIsDynamicInsightsOpen,
    setTaggingMode,
    documentTags,
    setShowDeleteDialog,
}: MultipleDocActionsProps) => {
    const copyLinksToClipboard = () => {
        const links = selectedDocuments.map((document) => {
            return `${window.location.origin}/client/insights/${document.id}`;
        });
        navigator.clipboard.writeText(links.join('\n'));
        toast.success('Document links have been copied to clipboard.');
    };

    return (
        <Flex
            style={{
                gap: '10px',
                paddingRight: '5px',
                paddingLeft: '5px',
            }}
            alignCenter
        >
            <Chip
                label={selectedDocuments.length}
                style={{
                    backgroundColor: '#DCDCDC',
                    color: Theme.primary,
                    fontWeight: 'bold',
                }}
            />
            <IconButton
                sx={{ '&:hover': { color: Theme.primary } }}
                onClick={() => {
                    setIsAddTagOpen(true);
                    setTaggingMode('adding');
                }}
            >
                <Tooltip id="button-tag" title="Add tag">
                    <Label />
                </Tooltip>
            </IconButton>
            {Boolean(Object.keys(documentTags).length) && (
                <IconButton
                    sx={{ '&:hover': { color: Theme.primary } }}
                    onClick={() => {
                        setIsAddTagOpen(true);
                        setTaggingMode('deleting');
                    }}
                >
                    <Tooltip id="button-tag" title="Remove tag">
                        <LabelOff />
                    </Tooltip>
                </IconButton>
            )}
            <IconButton
                sx={{ '&:hover': { color: Theme.primary } }}
                onClick={() => {
                    setIsDynamicInsightsOpen(true);
                }}
            >
                <Tooltip id="button-tag" title="Launch Dynamic Insights">
                    <QuestionAnswerOutlined />
                </Tooltip>
            </IconButton>
            <IconButton
                sx={{ '&:hover': { color: Theme.primary } }}
                onClick={() => copyLinksToClipboard()}
            >
                <Tooltip id="button-copy" title="Copy shareable links">
                    <CopyAll />
                </Tooltip>
            </IconButton>

            {/* TODO: Implement multi-download when endpoint is available */}
            <IconButton sx={{ '&:hover': { color: Theme.primary } }} disabled={true}>
                <Tooltip id="button-download" title="Download">
                    <Download />
                </Tooltip>
            </IconButton>
            {canChangeADocument() && (
                <IconButton
                    sx={{ '&:hover': { color: Theme.primary } }}
                    onClick={() => setShowDeleteDialog(true)}
                >
                    <Tooltip id="button-delete" title="Delete">
                        <Delete />
                    </Tooltip>
                </IconButton>
            )}
        </Flex>
    );
};

export default MultipleDocActions;
