import { Box, Modal } from '@mui/material';
import { ICollectionQuestion } from '../../../../@types/CollectionQuestion';
import { H5, Text } from '../../../UI/Typography';
import Container from '../../../UI/Common/Container';
import { CloseOutlined } from '@mui/icons-material';
import Flex from 'styled-flex-component';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
    height: 100,
};

interface AliasInfoModalProps {
    question?: ICollectionQuestion;
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
}

export default function AliasInfoModal({ question, isOpen, setIsOpen }: AliasInfoModalProps) {
    return (
        <Modal
            open={isOpen}
            aria-labelledby="save-question-collection-modal-title"
            aria-describedby="save-question-collection-modal-description"
        >
            <Box sx={style}>
                <Flex column justifyBetween full>
                    <Container>
                        <Flex justifyBetween alignTop>
                            <Container bottomOuterSpacing={0.75}>
                                <H5>{question?.alias}</H5>
                            </Container>
                            <Container
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                <CloseOutlined
                                    sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                                />
                            </Container>
                        </Flex>
                        <Container>
                            <Container>
                                <Container>
                                    <Text>
                                        <b>Question: </b>
                                    </Text>
                                </Container>
                                <Text>{question?.question}</Text>
                            </Container>
                            <Container topOuterSpacing={1}>
                                <Text>
                                    <b>Question Type: </b>
                                    {question?.question_type}
                                </Text>
                            </Container>
                        </Container>
                    </Container>
                </Flex>
            </Box>
        </Modal>
    );
}
