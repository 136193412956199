import { useMediaQuery } from 'react-responsive';
import AnnouncementBanner from '../../AnnouncementBanner/AnnouncementBanner';
import Container from '../Common/Container';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { useEffect, useState } from 'react';
import Flex from 'styled-flex-component';
import RepublicHeaderWidget from '../../RepublicHeaderWidget';

interface Props {
    children: React.ReactNode;
}

export default function DefaultLayout({ children }: Props) {
    const [isSideBarMaximized, setIsSideBarMaximized] = useState<boolean>(true);
    const isSmallerScreen = useMediaQuery({ query: `(max-width: 892px)` });

    const [isRepublicHeaderWidgetOpen, setIsRepublicHeaderWidgetOpen] = useState<boolean>(true);

    useEffect(() => {
        if (isSmallerScreen) {
            setIsSideBarMaximized(false);
            return;
        }

        setIsSideBarMaximized(true);
    }, [isSmallerScreen]);

    return (
        <>
            <RepublicHeaderWidget
                setIsRepublicHeaderWidgetOpen={setIsRepublicHeaderWidgetOpen}
                isRepublicHeaderWidgetOpen={isRepublicHeaderWidgetOpen}
            />
            <Navbar isSideBarMaximized={isSideBarMaximized} />
            <Flex>
                <Sidebar
                    isSmallerScreen={isSmallerScreen}
                    isSideBarMaximized={isSideBarMaximized}
                    isRepublicHeaderWidgetOpen={isRepublicHeaderWidgetOpen}
                    setIsSideBarMaximized={setIsSideBarMaximized}
                />
                <Container
                    width={isSideBarMaximized ? 'calc(100vw - 248px);' : '100vw'}
                    leftOuterSpacing={isSideBarMaximized ? '248px' : '80px'}
                    topOuterSpacing={4.5371125}
                    height={`calc(100vh - ${isRepublicHeaderWidgetOpen ? '140.5938px' : '72.594px'})`}
                    overflow="auto"
                    data-testid="client-page"
                >
                    <Container
                        topInnerSpacing={1.3}
                        bottomInnerSpacing={1.3}
                        leftInnerSpacing={1.5}
                        rightInnerSpacing={1.5}
                    >
                        <AnnouncementBanner />
                        {children}
                    </Container>
                </Container>
            </Flex>
        </>
    );
}
