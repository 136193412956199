import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, TableContainer } from '@mui/material';
import { IDocument } from '../../../../@types/Document';
import DocumentsTableItem from './Item';
import { Text } from '../../../UI/Typography';
import { useEffect, useState } from 'react';
import { Theme } from '../../../../theme';
import { isEmpty } from 'lodash';
import { MeliorTranslate } from '../../../MeliorTranslate';

interface IDocumentsTableProps {
    documents: IDocument[];
    refetch: () => void;
    selectedDocuments: IDocument[];
    setSelectedDocuments: (docs: IDocument[]) => void;
    removeDocuments: (docs: IDocument[]) => void;
    setIsAddTagOpen: (isOpen: boolean) => void;
    setTaggingMode: (mode: string) => void;
    setDocumentTags: (tags: object) => void;
    setTaggingDocIds: (ids: string[]) => void;
    setSelectedTags: (tags: string[]) => void;
    selectedTags: string[];
    setSelectedType: (type: string) => void;
    onDocumentReady: (doc: IDocument) => void;
    setIsDynamicInsightsOpen: (isOpen: boolean) => void;
    setDocumentsForDynInsights: (doc: IDocument[]) => void;
}

const DocumentsTable = ({
    documents,
    refetch,
    selectedDocuments,
    setSelectedDocuments,
    removeDocuments,
    setIsAddTagOpen,
    setTaggingMode,
    setDocumentTags,
    setTaggingDocIds,
    setSelectedTags,
    selectedTags,
    setSelectedType,
    onDocumentReady,
    setIsDynamicInsightsOpen,
    setDocumentsForDynInsights,
}: IDocumentsTableProps) => {
    const [isSelectingAll, setIsSelectingAll] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    useEffect(() => {
        setSelectedDocuments(isSelectingAll ? documents : []);
    }, [isSelectingAll]);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '10px' }}>
                            <Checkbox
                                onClick={() => {
                                    setIsSelectingAll(!isSelectingAll);
                                }}
                                indeterminate={
                                    documents.length != selectedDocuments.length &&
                                    !isEmpty(selectedDocuments)
                                }
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{
                                    color:
                                        (isHovered || isSelectingAll) &&
                                        !(
                                            documents.length != selectedDocuments.length &&
                                            !isEmpty(selectedDocuments)
                                        )
                                            ? Theme.primary
                                            : 'gray',
                                    padding: 0,
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="File Name" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Document Type" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Tags" />
                            </Text>
                        </TableCell>
                        <TableCell sx={{ width: '90px' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Status" />
                            </Text>
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((document: IDocument, index) => (
                        <DocumentsTableItem
                            documents={documents}
                            key={`${document.id}-${index}`}
                            document={document}
                            isSelectingAll={isSelectingAll}
                            selectedDocuments={selectedDocuments}
                            hasSelectedAll={documents.length == selectedDocuments.length}
                            selectedTags={selectedTags}
                            onDeleteSuccess={removeDocuments}
                            onReUploadSuccess={refetch}
                            setSelectedDocuments={setSelectedDocuments}
                            setIsAddTagOpen={setIsAddTagOpen}
                            setTaggingMode={setTaggingMode}
                            setDocumentTags={setDocumentTags}
                            setTaggingDocIds={setTaggingDocIds}
                            setSelectedTags={setSelectedTags}
                            setSelectedType={setSelectedType}
                            onDocumentReady={(document) => onDocumentReady(document)}
                            setIsDynamicInsightsOpen={setIsDynamicInsightsOpen}
                            setDocumentsForDynInsights={setDocumentsForDynInsights}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DocumentsTable;
