import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ISavedQuery } from '../../../@types/SavedQuery';
import SavedQueriesTableItem from './Item';
import { Text } from '../../UI/Typography';
import { MeliorTranslate } from '../../MeliorTranslate';

interface ISavedQueriesTableProps {
    savedQueries: ISavedQuery[];
    refetch: () => void;
}

const SavedQueriesTable = ({ savedQueries, refetch }: ISavedQueriesTableProps) => {
    const rows: ISavedQuery[] = savedQueries.map((item: ISavedQuery) => ({
        query_name: item.query_name,
        query_description: item.query_description,
        query: item.query,
        filters: item.filters,
    })) as ISavedQuery[];
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Name" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Description" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Actions" />
                            </Text>
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((savedQuery: ISavedQuery, index: number) => (
                        <SavedQueriesTableItem
                            key={index}
                            savedQuery={savedQuery}
                            onDeleteSuccess={refetch}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SavedQueriesTable;
