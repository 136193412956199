import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { IDynamicQueryCollection } from '../../../../@types/DynamicInsightQueryCollection';
import { MeliorTranslate } from '../../../MeliorTranslate';
import { toast } from 'react-toastify';
import { deleteQuestionCollection } from '../../../../api/dynamicInsights.api';
import { useState } from 'react';

interface Props {
    isOpen: boolean;
    collection: IDynamicQueryCollection;
    setIsOpen: (val: boolean) => void;
    handleDelete: () => void;
}

export default function DeleteQuestionCollectionModal({
    isOpen,
    collection,
    setIsOpen,
    handleDelete,
}: Props) {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    async function deleteCollection() {
        setIsDeleting(true);
        try {
            await deleteQuestionCollection(collection.id!);
            handleDelete();
        } catch (e) {
            console.error(e);
            setIsDeleting(false);
            toast.error('Something went wrong.');
        }
    }
    return (
        <Dialog open={isOpen}>
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete ?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpen(false)}>
                    <MeliorTranslate valueKey="Cancel" />
                </Button>
                <Button onClick={deleteCollection} autoFocus color="error" disabled={isDeleting}>
                    <MeliorTranslate valueKey="Delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
