import { IconButton, TableCell, TableRow } from '@mui/material';
import { ISavedQuery } from '../../../@types/SavedQuery';
import SavedQueryLink from '../../UI/SavedQueryLink';
import Container from '../../UI/Common/Container';
import { DeleteOutline, QuestionAnswerOutlined } from '@mui/icons-material';
import DeleteSavedQueryDialog from './DeleteSavedQueryDialog';
import { useEffect, useState } from 'react';
import LaunchDynamicInsightsModal from '../../Documents/LaunchDynamicInsightsModal';
import { IDocument } from '../../../@types/Document';
import { SearchResponse } from '../../../pages/Client/AdvancedSearch/Page';
import { searchDocuments } from '../../../api/search.api';
import Loading from '../../UI/Common/Loading';

interface Props {
    savedQuery: ISavedQuery;
    onDeleteSuccess: () => void;
}

export default function SavedQueriesTableItem({ savedQuery, onDeleteSuccess }: Props) {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isDynamicInsightsOpen, setIsDynamicInsightsOpen] = useState<boolean>(false);
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(true);

    async function fetchQueryDocuments() {
        try {
            const response: SearchResponse = await searchDocuments({
                query: savedQuery.query,
                filters: savedQuery.filters,
                store: false,
                query_name: '',
            });
            setIsLoadingDocuments(false);
            setSelectedDocuments(response.matching_docs);
        } catch (e) {
            console.error(e);
            setIsLoadingDocuments(false);
        }
    }

    useEffect(() => {
        fetchQueryDocuments();
    }, []);
    return (
        <>
            <TableRow
                key={savedQuery.query}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>
                    <Container maxWidth={17} wordBreak>
                        <SavedQueryLink savedQuery={savedQuery} />
                    </Container>
                </TableCell>
                <TableCell>{savedQuery.query_description}</TableCell>
                <TableCell>
                    {isLoadingDocuments && <Loading message={''} />}
                    {!isLoadingDocuments && (
                        <IconButton
                            disabled={!selectedDocuments.length}
                            color="inherit"
                            onClick={() => setIsDynamicInsightsOpen(true)}
                        >
                            <QuestionAnswerOutlined />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell>
                    <IconButton color="inherit" onClick={() => setShowDeleteDialog(true)}>
                        <DeleteOutline />
                    </IconButton>
                </TableCell>
            </TableRow>
            <DeleteSavedQueryDialog
                open={showDeleteDialog}
                savedQuery={savedQuery}
                onClose={() => {
                    setShowDeleteDialog(false);
                }}
                onDeleteSuccess={() => {
                    setShowDeleteDialog(false);
                    onDeleteSuccess();
                }}
            />
            <LaunchDynamicInsightsModal
                isOpen={isDynamicInsightsOpen}
                documents={selectedDocuments}
                setIsOpen={(isOpen) => setIsDynamicInsightsOpen(isOpen)}
            />
        </>
    );
}
