import { IDynamicQueryCollection } from '../@types/DynamicInsightQueryCollection';
import apiClient from './apiClient';

export const fetchQuestionCollections = async (params?) => {
    return apiClient
        .get(`/qcollections`, { params })
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const saveQuestionCollection = async (data: IDynamicQueryCollection) => {
    return apiClient
        .post(`/qcollections`, data)
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const getRecommendedType = async (question: string, provided_type: string) => {
    return apiClient
        .post(`/qcollections/recommended_qtypes`, [
            {
                question,
                provided_type,
            },
        ])
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const updateQuestionCollection = async (data: IDynamicQueryCollection) => {
    return apiClient
        .put(`/qcollections/${data.id}`, data)
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const deleteQuestionCollection = async (collectionId: string) => {
    return apiClient.delete(`/qcollections/${collectionId}`).then((res) => res.data);
};

export const fetchQuestionTypes = async () => {
    return apiClient.get('qcollections/qtypes').then((res) => res.data);
};

export const runDynamicQADoc = async (docIds: string[], collectionId: string) => {
    return apiClient
        .get('documents/qa_dynamic', {
            params: {
                doc_ids: docIds,
                collection_id: collectionId,
            },
            paramsSerializer: {
                indexes: null,
            },
        })
        .then((res) => res.data);
};
