import { MeliorTranslate } from '../../../MeliorTranslate';
import { H6 } from '../../Typography';
import { useLocation } from 'react-router-dom';

const paths = {
    'advanced-search': 'Advanced Search',
    'documents': 'Documents',
    'saved-queries': 'Saved Queries',
    'clause-library': 'Clause Library',
    'dynamic-insights': 'Dynamic Insights',
    'comparison': 'Comparisons',
    'workflows': 'Workflows',
    'insights-template': 'Insight Templates',
    'audit-logs': 'Audit Logs',
};

const NavbarPageTitle = () => {
    const location = useLocation();
    function renderPageTitle() {
        const pageTitle = Object.keys(paths).find((path) => location.pathname.includes(path));
        return (
            <H6>
                <MeliorTranslate valueKey={paths[pageTitle!]} />
            </H6>
        );
    }

    return <>{renderPageTitle()}</>;
};

export default NavbarPageTitle;
