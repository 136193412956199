import Flex from 'styled-flex-component';
import DocumentNameFilter from '../DocumentNameFilter';
import DocumentsTypeSelector from '../DocumentTypeSelector.tsx/DocumentTypeSelector';
import TagSelector from '../TagSelector.tsx';
import { IconButton } from '@mui/material';
import { SearchOff } from '@mui/icons-material';
import { ITagItem } from '../../../@types/Tag';

interface DocumentFiltersProps {
    searchText: string;
    selectedType: string;
    selectedTags?: string[];
    allTags?: Array<ITagItem>;
    setSearchText: (val: string) => void;
    setSelectedType: (val: string) => void;
    setSelectedTags?: (val: string[]) => void;
}

const DocumentFilters = ({
    searchText,
    selectedType,
    selectedTags,
    allTags,
    setSelectedType,
    setSearchText,
    setSelectedTags,
}: DocumentFiltersProps) => {
    return (
        <Flex style={{ gap: '15px' }} wrap>
            <DocumentNameFilter
                searchText={searchText}
                onChange={(searchKey) => setSearchText(searchKey)}
            />
            <DocumentsTypeSelector
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                isATableFilter
            />
            {setSelectedTags && allTags && selectedTags && (
                <TagSelector
                    allTags={allTags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    showLabel={true}
                    height="40px"
                    loadingTags={false}
                    showBalloons={true}
                />
            )}
            {Boolean(selectedType !== 'All' || selectedTags?.length || searchText.length) && (
                <IconButton
                    style={{ marginBottom: '10px' }}
                    onClick={() => {
                        setSelectedType('All');
                        setSearchText('');
                        if (setSelectedTags) {
                            setSelectedTags([]);
                        }
                    }}
                >
                    <SearchOff color="primary" />
                </IconButton>
            )}
        </Flex>
    );
};

export default DocumentFilters;
