import { IDocument } from '../../../@types/Document';
import DocumentTags from '../DocumentTags';
import Flex from 'styled-flex-component';

interface IDocumentTableItemTagsProps {
    document: IDocument;
    docTags: string[];
    selectedTags: string[];
    addTag: () => void;
    setSelectedTags: (tags: string[]) => void;
}

const DocumentTableItemTags = ({
    document,
    docTags,
    selectedTags,
    addTag,
    setSelectedTags,
}: IDocumentTableItemTagsProps) => {
    return (
        <span
            style={{
                height: '38px',
            }}
        >
            {docTags.length ? (
                <div
                    style={{
                        minWidth: '210px',
                        height: '58px',
                    }}
                >
                    <DocumentTags
                        documentData={document}
                        selectedTags={docTags}
                        onClickTag={(tag) => {
                            if (!selectedTags.includes(tag))
                                setSelectedTags(selectedTags.concat([tag]));
                        }}
                        onClickMore={() => addTag()}
                    />
                </div>
            ) : (
                <Flex alignCenter justifyBetween></Flex>
            )}
        </span>
    );
};

export default DocumentTableItemTags;
