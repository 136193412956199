import { IDocument } from '../../../@types/Document';
import { DYNAMIC_INSIGHT_PREPEND_INDICATOR } from '../../../constants/dynamicInsightIndicator';
import { IHighlight } from './types';

export function getDynamicInsightHighlights(documentData: IDocument, selectedInsight) {
    if (!selectedInsight) return [];
    if (!documentData.dynamic_insights) return [];

    const { answers } = documentData.dynamic_insights;
    const selectedAnswer = answers.find(
        (answer) =>
            answer.question.alias === selectedInsight.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, '')
    );

    const highlights: IHighlight[] = [];

    selectedAnswer?.bboxes.map((bbox, index) => {
        const arr: number[] = bbox[0];

        if (arr.length) {
            const left = arr[0];
            const top = arr[1];
            const width = arr[2];
            const height = arr[3];
            highlights.push({
                pageIndex: selectedAnswer.pages[index],
                height,
                left,
                top,
                width,
                text: selectedAnswer.answer,
                isSelected: true,
            });
        }
    });

    return highlights;
}
