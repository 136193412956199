import { Button, CircularProgress, TableCell, TableRow } from '@mui/material';
import Container from '../../../UI/Common/Container';
import { Text } from '../../../UI/Typography';
import { MeliorTranslate } from '../../../MeliorTranslate';
import Flex from 'styled-flex-component';
import { useState } from 'react';
import { IDynamicQueryCollection } from '../../../../@types/DynamicInsightQueryCollection';
import { ICollectionQuestion } from '../../../../@types/CollectionQuestion';
import AliasInfoModal from '../../../DynamicInsights/Modals/AliasInfo';
import { runDynamicQADoc } from '../../../../api/dynamicInsights.api';
import { IDocument } from '../../../../@types/Document';
import { toast } from 'react-toastify';
import { CheckCircle } from '@mui/icons-material';
import { Theme } from '../../../../theme';

interface ILaunchDynamicInsightsTableItemProps {
    collectionItem: IDynamicQueryCollection;
    refetch: () => void;
    selectedCollection: IDynamicQueryCollection;
    setSelectedCollection: (val: IDynamicQueryCollection) => void;
    documents: IDocument[];
}

export default function LaunchDynamicInsightsTableItem({
    collectionItem,
    refetch,
    selectedCollection,
    setSelectedCollection,
    documents,
}: ILaunchDynamicInsightsTableItemProps) {
    const [isOpenAlias, setIsOpenAlias] = useState<boolean>(false);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [selectedAlias, setSelectedAlias] = useState<ICollectionQuestion>();

    async function runCollection() {
        setIsRunning(true);
        try {
            await runDynamicQADoc(
                documents.map((doc) => doc.id),
                collectionItem.id!
            );
            toast.success(`Successfully ran ${collectionItem.name}`);
            setIsRunning(false);
            setSelectedCollection(collectionItem);
        } catch (e) {
            toast.error('Something went wrong.');
            setIsRunning(false);
            refetch();
        }
    }

    function openAlias(alias) {
        setSelectedAlias(alias);
        setIsOpenAlias(true);
    }

    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: '30%' }}>{collectionItem.name}</TableCell>
                <TableCell style={{ width: '45%' }}>
                    {collectionItem.questions.map((question) => (
                        <Container
                            key={question.alias}
                            cursor="pointer"
                            onClick={() => openAlias(question)}
                        >
                            <Text color="rgb(3, 138, 255)">{question.alias}</Text>{' '}
                        </Container>
                    ))}
                </TableCell>
                <TableCell style={{ width: '25%' }}>
                    <Flex alignCenter justifyBetween>
                        {selectedCollection && selectedCollection.id == collectionItem.id ? (
                            <Flex
                                style={{
                                    width: '150px',
                                    background: Theme.success,
                                    color: Theme.whiteColor,
                                    gap: '10px',
                                    height: '38px',
                                    borderRadius: '5px',
                                }}
                                alignCenter
                                justifyCenter
                            >
                                <CheckCircle />
                                <Text>
                                    <MeliorTranslate valueKey="Done" />
                                </Text>
                            </Flex>
                        ) : (
                            <Button
                                variant="outlined"
                                onClick={() => runCollection()}
                                disabled={isRunning}
                                style={{ width: '150px', height: '38px' }}
                            >
                                {isRunning ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <Text>
                                        <MeliorTranslate valueKey={'Run Insight'} />
                                    </Text>
                                )}
                            </Button>
                        )}
                    </Flex>
                </TableCell>
            </TableRow>
            <AliasInfoModal
                question={selectedAlias}
                isOpen={isOpenAlias}
                setIsOpen={setIsOpenAlias}
            />
        </>
    );
}
