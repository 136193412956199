import Container from '../../Common/Container';
import { StyledListItem, StyledUnorderedList } from './Block.styled';
import Flex from 'styled-flex-component';
import { Link, To, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import sidebarBlocks, { IBlock } from './sidebarBlocks';

interface SideBarBlockProps {
    isSideBarMaximized: boolean;
}

const SideBarBlock = ({ isSideBarMaximized }: SideBarBlockProps) => {
    const location = useLocation();
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState<To>(sidebarBlocks(t)[0].slug);
    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location]);
    return (
        <section>
            <StyledUnorderedList>
                {sidebarBlocks(t).map((block: IBlock) => (
                    <StyledListItem
                        isDisabled={block.isDisabled}
                        isActive={activeTab === block.slug}
                        key={block.slug}
                    >
                        <Link to={block.isDisabled ? '#' : block.slug}>
                            <Container className="linkContainer">
                                <Flex alignCenter justifyBetween>
                                    <Flex alignCenter>
                                        <Container rightOuterSpacing={0.9375}>
                                            {block.icon}
                                        </Container>
                                        {isSideBarMaximized && <>{block.name}</>}
                                    </Flex>
                                    {isSideBarMaximized && block.isExpandable && <ExpandMoreIcon />}
                                </Flex>
                            </Container>
                        </Link>
                    </StyledListItem>
                ))}
            </StyledUnorderedList>
        </section>
    );
};

export default SideBarBlock;
