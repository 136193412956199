import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import Flex from 'styled-flex-component';
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { Text } from '../../../components/UI/Typography';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { MeliorTable } from '../../../components/MeliorTable';
import { useState } from 'react';
import { PAGINATION_LIMIT } from '../../../constants';
import DynamicInsightsTable from '../../../components/DynamicInsights/Table';
import { useDynamicInsightsCollection } from '../../../hooks/useDynamicInsightsCollection';
import SaveQuestionCollectionForm from '../../../components/DynamicInsights/Forms/SaveQuestionCollection';
import { IDynamicQueryCollection } from '../../../@types/DynamicInsightQueryCollection';

const DynamicInsightsPage = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [isShowCollectionForm, setIsShowCollectionForm] = useState<boolean>(false);
    const [selectedCollection, setSelectedCollection] = useState<IDynamicQueryCollection | null>();

    const params = {
        page: page,
        size: PAGINATION_LIMIT,
    };

    const { isLoading, error, totalPages, collections, refetch } =
        useDynamicInsightsCollection(params);

    const showLoading = isLoading;

    return (
        <Container>
            {(isShowCollectionForm || selectedCollection) && (
                <SaveQuestionCollectionForm
                    refetch={refetch}
                    setSelectedCollection={setSelectedCollection}
                    collection={selectedCollection}
                    setIsShowCollectionForm={setIsShowCollectionForm}
                />
            )}
            {!(isShowCollectionForm || selectedCollection) && (
                <>
                    <Grid container justifyContent="flex-end" sx={{ marginBottom: 2 }}>
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => setIsShowCollectionForm(true)}
                        >
                            <Text uppercase customletterSpacing={0.05}>
                                <MeliorTranslate valueKey="Create Collection" />
                            </Text>
                        </Button>
                    </Grid>
                    <CardContainer>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Flex justifyBetween>
                                <Tabs value={0} style={{ overflow: 'visible' }}>
                                    <Tab
                                        label={<MeliorTranslate valueKey="Question Collections" />}
                                    />
                                </Tabs>
                            </Flex>
                        </Box>
                        <TabPanel value={0} index={0}>
                            <MeliorTable
                                showLoading={showLoading}
                                error={error}
                                items={collections}
                                page={page}
                                totalPages={totalPages}
                                setPage={setPage}
                                tableEl={
                                    <DynamicInsightsTable
                                        setSelectedCollection={setSelectedCollection}
                                        collections={collections}
                                        refetch={refetch}
                                    />
                                }
                                loadingMessage="Loading question collections"
                                emptyMessage={t('No question collections found')}
                                errorMessage="An error has occurred"
                            />
                        </TabPanel>
                    </CardContainer>
                </>
            )}
        </Container>
    );
};

export default DynamicInsightsPage;
