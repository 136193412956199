import { useQuery } from '@tanstack/react-query';
import { fetchQuestionTypes } from '../api/dynamicInsights.api';

export function useQuestionTypes() {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchQuestionTypes(),
        queryKey: ['question-types'],
        refetchOnWindowFocus: false,
    });

    return {
        questionTypes: data || ([] as string[]),
        totalPages: data?.pages,
        ...rest,
    };
}
