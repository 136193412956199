import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import {
    SearchOutlined,
    LocalLibrary,
    FileOpenOutlined,
    QuestionAnswerOutlined,
} from '@mui/icons-material';
import { To } from 'react-router-dom';

export interface IBlock {
    name: string;
    icon: React.ReactNode;
    slug: To;
    isExpandable?: boolean;
    isDisabled?: boolean;
}

const rootRoute = '/client';

const sidebarBlocks = (t: (val: string) => string): IBlock[] => {
    return [
        {
            name: t('Documents'),
            icon: <DescriptionOutlinedIcon />,
            slug: `${rootRoute}/documents`,
            isExpandable: false,
        },
        {
            name: t('Advanced Search'),
            icon: <SearchOutlined />,
            slug: `${rootRoute}/advanced-search`,
            isExpandable: false,
        },
        {
            name: t('Saved Queries'),
            icon: <QuizOutlinedIcon />,
            slug: `${rootRoute}/saved-queries`,
        },
        {
            name: t('Clause Library'),
            icon: <LocalLibrary />,
            slug: `${rootRoute}/clause-library`,
        },
        {
            name: t('Dynamic Insights'),
            icon: <QuestionAnswerOutlined />,
            slug: `${rootRoute}/dynamic-insights`,
        },
        {
            name: t('Insight Templates'),
            icon: <ContentPasteSearchOutlinedIcon />,
            slug: `${rootRoute}/insights-template`,
        },
        {
            name: t('Approved Clauses'),
            icon: <FactCheckOutlinedIcon />,
            slug: `${rootRoute}/approved-clauses`,
            isDisabled: true,
        },
        {
            name: t('Comparisons'),
            icon: <CompareArrowsOutlinedIcon />,
            slug: `${rootRoute}/comparisons`,
        },
        {
            name: t('Workflows'),
            icon: <AccountTreeOutlinedIcon />,
            slug: `${rootRoute}/workflows`,
            isExpandable: false,
        },
        {
            name: t('Collaboration'),
            icon: <Groups2OutlinedIcon />,
            slug: `${rootRoute}/collaboration`,
            isDisabled: true,
        },
        {
            name: t('Audit Logs'),
            icon: <FileOpenOutlined />,
            slug: `${rootRoute}/audit-logs`,
            isDisabled: false,
        },
    ];
};

export default sidebarBlocks;
