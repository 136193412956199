import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DynamicInsightTableItem from './Item';
import { Text } from '../../UI/Typography';
import { MeliorTranslate } from '../../MeliorTranslate';
import { IDynamicQueryCollection } from '../../../@types/DynamicInsightQueryCollection';

interface IDynamicInsightsTableProps {
    collections: IDynamicQueryCollection[];
    setSelectedCollection: (val: IDynamicQueryCollection) => void;
    refetch: () => void;
}

const DynamicInsightsTable = ({
    collections,
    setSelectedCollection,
    refetch,
}: IDynamicInsightsTableProps) => {
    const rows: IDynamicQueryCollection[] = collections.map((item: IDynamicQueryCollection) => ({
        name: item.name,
        questions: item.questions,
        id: item.id,
    })) as IDynamicQueryCollection[];
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Question Collection Name" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Questions" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Actions" />
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((item) => (
                        <DynamicInsightTableItem
                            collectionItem={item}
                            setSelectedCollection={setSelectedCollection}
                            key={item.id}
                            refetch={refetch}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DynamicInsightsTable;
