import * as yup from 'yup';

export const collectionValidationSchema = yup.object({
    collectionName: yup.string().required('Collection name is required'),
    questions: yup.array().of(
        yup.object().shape({
            question: yup.string().required('Question is required'),
            question_type: yup.string().required('Question type is required'),
            alias: yup.string().required('Alias is required'),
        })
    ),
});
