import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import LaunchDynamicInsightsTableItem from './Item';
import { Text } from '../../../UI/Typography';
import { MeliorTranslate } from '../../../MeliorTranslate';
import { IDynamicQueryCollection } from '../../../../@types/DynamicInsightQueryCollection';
import { IDocument } from '../../../../@types/Document';
import { useState } from 'react';

interface ILaunchDynamicInsightsTableProps {
    collections: IDynamicQueryCollection[];
    refetch: () => void;
    documents: IDocument[];
}

const LaunchDynamicInsightsTable = ({
    collections,
    refetch,
    documents,
}: ILaunchDynamicInsightsTableProps) => {
    const rows: IDynamicQueryCollection[] = collections.map((item: IDynamicQueryCollection) => ({
        name: item.name,
        questions: item.questions,
        id: item.id,
    })) as IDynamicQueryCollection[];

    const [selectedCollection, setSelectedCollection] = useState<IDynamicQueryCollection | null>();
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Question Collection Name" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Questions" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Action" />
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((item) => (
                        <LaunchDynamicInsightsTableItem
                            collectionItem={item}
                            key={item.id}
                            refetch={refetch}
                            documents={documents}
                            selectedCollection={selectedCollection as IDynamicQueryCollection}
                            setSelectedCollection={setSelectedCollection}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LaunchDynamicInsightsTable;
